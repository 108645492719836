import { serializeError, deserializeError, createCustomErrorClass, addCustomErrorDeserializer, } from "./helpers";
export { serializeError, deserializeError, createCustomErrorClass, addCustomErrorDeserializer, };
export var AccountNameRequiredError = createCustomErrorClass("AccountNameRequired");
export var AccountNotSupported = createCustomErrorClass("AccountNotSupported");
export var AmountRequired = createCustomErrorClass("AmountRequired");
export var BluetoothRequired = createCustomErrorClass("BluetoothRequired");
export var BtcUnmatchedApp = createCustomErrorClass("BtcUnmatchedApp");
export var CantOpenDevice = createCustomErrorClass("CantOpenDevice");
export var CashAddrNotSupported = createCustomErrorClass("CashAddrNotSupported");
export var CurrencyNotSupported = createCustomErrorClass("CurrencyNotSupported");
export var DeviceAppVerifyNotSupported = createCustomErrorClass("DeviceAppVerifyNotSupported");
export var DeviceGenuineSocketEarlyClose = createCustomErrorClass("DeviceGenuineSocketEarlyClose");
export var DeviceNotGenuineError = createCustomErrorClass("DeviceNotGenuine");
export var DeviceOnDashboardExpected = createCustomErrorClass("DeviceOnDashboardExpected");
export var DeviceOnDashboardUnexpected = createCustomErrorClass("DeviceOnDashboardUnexpected");
export var DeviceInOSUExpected = createCustomErrorClass("DeviceInOSUExpected");
export var DeviceHalted = createCustomErrorClass("DeviceHalted");
export var DeviceNameInvalid = createCustomErrorClass("DeviceNameInvalid");
export var DeviceSocketFail = createCustomErrorClass("DeviceSocketFail");
export var DeviceSocketNoBulkStatus = createCustomErrorClass("DeviceSocketNoBulkStatus");
export var DisconnectedDevice = createCustomErrorClass("DisconnectedDevice");
export var DisconnectedDeviceDuringOperation = createCustomErrorClass("DisconnectedDeviceDuringOperation");
export var EnpointConfigError = createCustomErrorClass("EnpointConfig");
export var EthAppPleaseEnableContractData = createCustomErrorClass("EthAppPleaseEnableContractData");
export var FeeEstimationFailed = createCustomErrorClass("FeeEstimationFailed");
export var FirmwareNotRecognized = createCustomErrorClass("FirmwareNotRecognized");
export var HardResetFail = createCustomErrorClass("HardResetFail");
export var InvalidXRPTag = createCustomErrorClass("InvalidXRPTag");
export var InvalidAddress = createCustomErrorClass("InvalidAddress");
export var InvalidAddressBecauseDestinationIsAlsoSource = createCustomErrorClass("InvalidAddressBecauseDestinationIsAlsoSource");
export var LatestMCUInstalledError = createCustomErrorClass("LatestMCUInstalledError");
export var UnknownMCU = createCustomErrorClass("UnknownMCU");
export var LedgerAPIError = createCustomErrorClass("LedgerAPIError");
export var LedgerAPIErrorWithMessage = createCustomErrorClass("LedgerAPIErrorWithMessage");
export var LedgerAPINotAvailable = createCustomErrorClass("LedgerAPINotAvailable");
export var ManagerAppAlreadyInstalledError = createCustomErrorClass("ManagerAppAlreadyInstalled");
export var ManagerAppRelyOnBTCError = createCustomErrorClass("ManagerAppRelyOnBTC");
export var ManagerAppDepInstallRequired = createCustomErrorClass("ManagerAppDepInstallRequired");
export var ManagerAppDepUninstallRequired = createCustomErrorClass("ManagerAppDepUninstallRequired");
export var ManagerDeviceLockedError = createCustomErrorClass("ManagerDeviceLocked");
export var ManagerFirmwareNotEnoughSpaceError = createCustomErrorClass("ManagerFirmwareNotEnoughSpace");
export var ManagerNotEnoughSpaceError = createCustomErrorClass("ManagerNotEnoughSpace");
export var ManagerUninstallBTCDep = createCustomErrorClass("ManagerUninstallBTCDep");
export var NetworkDown = createCustomErrorClass("NetworkDown");
export var NoAddressesFound = createCustomErrorClass("NoAddressesFound");
export var NotEnoughBalance = createCustomErrorClass("NotEnoughBalance");
export var NotEnoughBalanceToDelegate = createCustomErrorClass("NotEnoughBalanceToDelegate");
export var NotEnoughBalanceInParentAccount = createCustomErrorClass("NotEnoughBalanceInParentAccount");
export var NotEnoughSpendableBalance = createCustomErrorClass("NotEnoughSpendableBalance");
export var NotEnoughBalanceBecauseDestinationNotCreated = createCustomErrorClass("NotEnoughBalanceBecauseDestinationNotCreated");
export var NoAccessToCamera = createCustomErrorClass("NoAccessToCamera");
export var NotEnoughGas = createCustomErrorClass("NotEnoughGas");
export var NotSupportedLegacyAddress = createCustomErrorClass("NotSupportedLegacyAddress");
export var GasLessThanEstimate = createCustomErrorClass("GasLessThanEstimate");
export var PasswordsDontMatchError = createCustomErrorClass("PasswordsDontMatch");
export var PasswordIncorrectError = createCustomErrorClass("PasswordIncorrect");
export var RecommendSubAccountsToEmpty = createCustomErrorClass("RecommendSubAccountsToEmpty");
export var RecommendUndelegation = createCustomErrorClass("RecommendUndelegation");
export var TimeoutTagged = createCustomErrorClass("TimeoutTagged");
export var UnexpectedBootloader = createCustomErrorClass("UnexpectedBootloader");
export var MCUNotGenuineToDashboard = createCustomErrorClass("MCUNotGenuineToDashboard");
export var RecipientRequired = createCustomErrorClass("RecipientRequired");
export var UnavailableTezosOriginatedAccountReceive = createCustomErrorClass("UnavailableTezosOriginatedAccountReceive");
export var UnavailableTezosOriginatedAccountSend = createCustomErrorClass("UnavailableTezosOriginatedAccountSend");
export var UpdateFetchFileFail = createCustomErrorClass("UpdateFetchFileFail");
export var UpdateIncorrectHash = createCustomErrorClass("UpdateIncorrectHash");
export var UpdateIncorrectSig = createCustomErrorClass("UpdateIncorrectSig");
export var UpdateYourApp = createCustomErrorClass("UpdateYourApp");
export var UserRefusedDeviceNameChange = createCustomErrorClass("UserRefusedDeviceNameChange");
export var UserRefusedAddress = createCustomErrorClass("UserRefusedAddress");
export var UserRefusedFirmwareUpdate = createCustomErrorClass("UserRefusedFirmwareUpdate");
export var UserRefusedAllowManager = createCustomErrorClass("UserRefusedAllowManager");
export var UserRefusedOnDevice = createCustomErrorClass("UserRefusedOnDevice"); // TODO rename because it's just for transaction refusal
export var TransportOpenUserCancelled = createCustomErrorClass("TransportOpenUserCancelled");
export var TransportInterfaceNotAvailable = createCustomErrorClass("TransportInterfaceNotAvailable");
export var TransportRaceCondition = createCustomErrorClass("TransportRaceCondition");
export var TransportWebUSBGestureRequired = createCustomErrorClass("TransportWebUSBGestureRequired");
export var DeviceShouldStayInApp = createCustomErrorClass("DeviceShouldStayInApp");
export var WebsocketConnectionError = createCustomErrorClass("WebsocketConnectionError");
export var WebsocketConnectionFailed = createCustomErrorClass("WebsocketConnectionFailed");
export var WrongDeviceForAccount = createCustomErrorClass("WrongDeviceForAccount");
export var WrongAppForCurrency = createCustomErrorClass("WrongAppForCurrency");
export var ETHAddressNonEIP = createCustomErrorClass("ETHAddressNonEIP");
export var CantScanQRCode = createCustomErrorClass("CantScanQRCode");
export var FeeNotLoaded = createCustomErrorClass("FeeNotLoaded");
export var FeeRequired = createCustomErrorClass("FeeRequired");
export var FeeTooHigh = createCustomErrorClass("FeeTooHigh");
export var SyncError = createCustomErrorClass("SyncError");
export var PairingFailed = createCustomErrorClass("PairingFailed");
export var GenuineCheckFailed = createCustomErrorClass("GenuineCheckFailed");
export var LedgerAPI4xx = createCustomErrorClass("LedgerAPI4xx");
export var LedgerAPI5xx = createCustomErrorClass("LedgerAPI5xx");
export var FirmwareOrAppUpdateRequired = createCustomErrorClass("FirmwareOrAppUpdateRequired");
// db stuff, no need to translate
export var NoDBPathGiven = createCustomErrorClass("NoDBPathGiven");
export var DBWrongPassword = createCustomErrorClass("DBWrongPassword");
export var DBNotReset = createCustomErrorClass("DBNotReset");
/**
 * TransportError is used for any generic transport errors.
 * e.g. Error thrown when data received by exchanges are incorrect or if exchanged failed to communicate with the device for various reason.
 */
export function TransportError(message, id) {
    this.name = "TransportError";
    this.message = message;
    this.stack = new Error().stack;
    this.id = id;
}
TransportError.prototype = new Error();
addCustomErrorDeserializer("TransportError", function (e) { return new TransportError(e.message, e.id); });
export var StatusCodes = {
    PIN_REMAINING_ATTEMPTS: 0x63c0,
    INCORRECT_LENGTH: 0x6700,
    MISSING_CRITICAL_PARAMETER: 0x6800,
    COMMAND_INCOMPATIBLE_FILE_STRUCTURE: 0x6981,
    SECURITY_STATUS_NOT_SATISFIED: 0x6982,
    CONDITIONS_OF_USE_NOT_SATISFIED: 0x6985,
    INCORRECT_DATA: 0x6a80,
    NOT_ENOUGH_MEMORY_SPACE: 0x6a84,
    REFERENCED_DATA_NOT_FOUND: 0x6a88,
    FILE_ALREADY_EXISTS: 0x6a89,
    INCORRECT_P1_P2: 0x6b00,
    INS_NOT_SUPPORTED: 0x6d00,
    CLA_NOT_SUPPORTED: 0x6e00,
    TECHNICAL_PROBLEM: 0x6f00,
    OK: 0x9000,
    MEMORY_PROBLEM: 0x9240,
    NO_EF_SELECTED: 0x9400,
    INVALID_OFFSET: 0x9402,
    FILE_NOT_FOUND: 0x9404,
    INCONSISTENT_FILE: 0x9408,
    ALGORITHM_NOT_SUPPORTED: 0x9484,
    INVALID_KCV: 0x9485,
    CODE_NOT_INITIALIZED: 0x9802,
    ACCESS_CONDITION_NOT_FULFILLED: 0x9804,
    CONTRADICTION_SECRET_CODE_STATUS: 0x9808,
    CONTRADICTION_INVALIDATION: 0x9810,
    CODE_BLOCKED: 0x9840,
    MAX_VALUE_REACHED: 0x9850,
    GP_AUTH_FAILED: 0x6300,
    LICENSING: 0x6f42,
    HALTED: 0x6faa
};
export function getAltStatusMessage(code) {
    switch (code) {
        // improve text of most common errors
        case 0x6700:
            return "Incorrect length";
        case 0x6800:
            return "Missing critical parameter";
        case 0x6982:
            return "Security not satisfied (dongle locked or have invalid access rights)";
        case 0x6985:
            return "Condition of use not satisfied (denied by the user?)";
        case 0x6a80:
            return "Invalid data received";
        case 0x6b00:
            return "Invalid parameter received";
    }
    if (0x6f00 <= code && code <= 0x6fff) {
        return "Internal error, please report";
    }
}
/**
 * Error thrown when a device returned a non success status.
 * the error.statusCode is one of the `StatusCodes` exported by this library.
 */
export function TransportStatusError(statusCode) {
    this.name = "TransportStatusError";
    var statusText = Object.keys(StatusCodes).find(function (k) { return StatusCodes[k] === statusCode; }) ||
        "UNKNOWN_ERROR";
    var smsg = getAltStatusMessage(statusCode) || statusText;
    var statusCodeStr = statusCode.toString(16);
    this.message = "Ledger device: " + smsg + " (0x" + statusCodeStr + ")";
    this.stack = new Error().stack;
    this.statusCode = statusCode;
    this.statusText = statusText;
}
TransportStatusError.prototype = new Error();
addCustomErrorDeserializer("TransportStatusError", function (e) { return new TransportStatusError(e.statusCode); });
